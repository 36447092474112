var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-overlay", {
        staticClass: "overlay",
        attrs: { value: _vm.showNotificationPane },
      }),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: {
            transition: "slide-y-transition",
            "close-on-content-click": false,
            "nudge-width": 340,
            "offset-y": "",
            "nudge-bottom": 20,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b({ attrs: { icon: "" } }, "v-btn", attrs, false),
                      on
                    ),
                    [
                      _vm.unreadCount === 0
                        ? _c("v-icon", { attrs: { size: _vm.size } }, [
                            _vm._v("\n          mdi-bell-outline\n        "),
                          ])
                        : _c(
                            "v-badge",
                            {
                              attrs: {
                                left: "",
                                "offset-x": "24",
                                "offset-y": "8",
                                color: _vm.badgeColor,
                                bordered: "",
                                content: _vm.unreadCount,
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    size: _vm.size,
                                    color: _vm.badgeColor,
                                  },
                                },
                                [_vm._v(" mdi-bell ")]
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showNotificationPane,
            callback: function ($$v) {
              _vm.showNotificationPane = $$v
            },
            expression: "showNotificationPane",
          },
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v("\n        Notifications\n        "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "", "open-delay": "350" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "ml-1",
                                      attrs: { icon: "" },
                                      on: { click: _vm.clearNotifications },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v(" mdi-notification-clear-all "),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_vm._v(" "), _c("span", [_vm._v("Clear all")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-1",
                      attrs: { icon: "" },
                      on: { click: _vm.toggleSettingsDialog },
                    },
                    [_c("v-icon", [_vm._v(" $astro-settings ")])],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.notifications.length === 0
                ? _c("v-card-text", [
                    _vm._v("\n        No notifications\n      "),
                  ])
                : _c(
                    "v-list",
                    {
                      staticClass: "overflow-y-auto",
                      attrs: {
                        "two-line": "",
                        width: "388",
                        "max-height": "80vh",
                      },
                    },
                    [
                      _vm._l(
                        _vm.notificationList,
                        function (notification, index) {
                          return [
                            notification.header
                              ? [
                                  index !== 0
                                    ? _c("v-divider", {
                                        key: index,
                                        staticClass: "mb-2",
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-subheader",
                                    { key: notification.header },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(notification.header) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              : _c(
                                  "v-list-item",
                                  {
                                    key: "notification-" + index,
                                    staticClass: "pl-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDialog(notification)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-badge",
                                      {
                                        attrs: {
                                          left: "",
                                          inline: "",
                                          color: "transparent",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "badge",
                                              fn: function () {
                                                return [
                                                  _c("astro-status-indicator", {
                                                    attrs: {
                                                      status:
                                                        notification.severity.toLowerCase(),
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "pt-0 pb-0" },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              {
                                                class: {
                                                  "text--secondary":
                                                    notification.read,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(notification.title) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("v-list-item-subtitle", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(notification.body) +
                                                  "\n                "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-action",
                                          { staticClass: "mt-0" },
                                          [
                                            _c("v-list-item-action-text", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm._f("shortDateTime")(
                                                      notification.time
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("v-spacer"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.notificationDialog,
            callback: function ($$v) {
              _vm.notificationDialog = $$v
            },
            expression: "notificationDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.selectedNotification.title) +
                      "\n        "
                  ),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("astro-status-indicator", {
                    attrs: {
                      status: _vm.selectedNotification.severity || "normal",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-subtitle", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("shortDateTime")(_vm.selectedNotification.time)
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.selectedNotification.body) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.navigate(_vm.selectedNotification.url)
                        },
                      },
                    },
                    [
                      _vm._v("\n          Open\n          "),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v(" mdi-open-in-new "),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.notificationDialog = false
                        },
                      },
                    },
                    [_vm._v("\n          Dismiss\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.settingsDialog,
            callback: function ($$v) {
              _vm.settingsDialog = $$v
            },
            expression: "settingsDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Notification settings ")]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("v-switch", {
                    attrs: { label: "Show toasts" },
                    model: {
                      value: _vm.showToast,
                      callback: function ($$v) {
                        _vm.showToast = $$v
                      },
                      expression: "showToast",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.toggleSettingsDialog },
                    },
                    [_vm._v("\n          close\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }