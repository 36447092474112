var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      attrs: {
        id: "footer",
        app: "",
        color: "tertiary darken-3",
        height: "33",
      },
    },
    [
      _c("img", { attrs: { src: _vm.icon, alt: "REORBIT" } }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "footer-text", staticStyle: { "margin-left": "5px" } },
        [
          _vm._v(
            "\n    OpenC3 " +
              _vm._s(_vm.edition) +
              " " +
              _vm._s(_vm.openc3Version) +
              " © 2022 - License:\n    " +
              _vm._s(_vm.license) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "justify-right" }, [_c("clock-footer")], 1),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }