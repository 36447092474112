var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      style: _vm.style,
      attrs: { id: "rux-icon", title: _vm.label, size: _vm.size },
    },
    [
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 128 128",
            preserveAspectRatio: "xMidYMid meet",
            focusable: "false",
          },
        },
        [_c("use", { attrs: { href: _vm.getIcon(this.library, this.icon) } })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }