var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { style: _vm.classificationStyles },
    [
      _c("app-nav", {
        staticClass: "d-print-none",
        attrs: { edition: "REORBIT" },
      }),
      _vm._v(" "),
      _c(
        "v-main",
        [
          _c("v-container", { attrs: { fluid: "" } }, [
            _c("div", { attrs: { id: "openc3-tool" } }),
            _vm._v(" "),
            _c("div", [_c("router-view")], 1),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("app-footer", {
        staticClass: "d-print-none",
        attrs: { app: "", edition: "REORBIT", license: "AGPLv3" },
      }),
      _vm._v(" "),
      _c("time-check"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }