var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" Clock out of sync ")]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _vm._v(
                "\n      We've detected that your clock is approximately\n      " +
                  _vm._s(Math.abs(_vm.approximateDiscrepancySec)) +
                  " seconds\n      " +
                  _vm._s(
                    _vm.approximateDiscrepancySec > 0 ? "behind" : "ahead of"
                  ) +
                  " server time.\n      This can cause issues and might have unknown side effects.\n      "
              ),
              _c("v-checkbox", {
                attrs: { label: "Don't show this again" },
                model: {
                  value: _vm.suppress,
                  callback: function ($$v) {
                    _vm.suppress = $$v
                  },
                  expression: "suppress",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.dismiss },
                },
                [_vm._v(" Dismiss ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }