import { render, staticRenderFns } from "./RuxIcon.vue?vue&type=template&id=342f670e&"
import script from "./RuxIcon.vue?vue&type=script&lang=js&"
export * from "./RuxIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../openc3-tool-base/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/openc3/plugins/openc3-tool-base/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('342f670e')) {
      api.createRecord('342f670e', component.options)
    } else {
      api.reload('342f670e', component.options)
    }
    module.hot.accept("./RuxIcon.vue?vue&type=template&id=342f670e&", function () {
      api.rerender('342f670e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "packages/openc3-tool-common/src/components/icons/RuxIcon.vue"
export default component.exports