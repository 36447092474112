var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "span",
        _vm._g(
          _vm._b(
            {
              staticClass: "font-weight-bold",
              staticStyle: { cursor: "context-menu" },
              on: { contextmenu: _vm.openMenu },
            },
            "span",
            _vm.attrs,
            false
          ),
          _vm.on
        ),
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm._f("date")(
                  _vm.displayLocal ? _vm.localDate : _vm.utcDate,
                  _vm.formatString
                )
              ) +
              "\n    (" +
              _vm._s(_vm.displayLocal ? "local" : "UTC") +
              ")\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: {
            "position-x": _vm.menuX,
            "position-y": _vm.menuY,
            absolute: "",
            "offset-y": "",
          },
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.toggleDisplayLocal },
                    },
                    [
                      _vm._v(
                        "\n          Display " +
                          _vm._s(_vm.displayLocal ? "UTC" : "local time") +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.toggleDisplay24h },
                    },
                    [
                      _vm._v(
                        "\n          Display " +
                          _vm._s(_vm.display24h ? 12 : 24) +
                          " hour clock\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _vm.dateMode !== "monthFirst"
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-title",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.setMonthFirst },
                        },
                        [_vm._v("\n          Display mm/dd/yyyy\n        ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dateMode !== "dayFirst"
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-title",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.setDayFirst },
                        },
                        [_vm._v("\n          Display dd/mm/yyyy\n        ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.dateMode !== "number"
                ? _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-title",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.setNumber },
                        },
                        [_vm._v("\n          Display day of year\n        ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }