var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-overlay", {
        staticClass: "overlay",
        attrs: { value: _vm.showAlertPane },
      }),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: {
            transition: "slide-y-transition",
            "close-on-content-click": false,
            "nudge-width": 340,
            "offset-y": "",
            "nudge-bottom": 20,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b({ attrs: { icon: "" } }, "v-btn", attrs, false),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { size: _vm.size } }, [
                        _vm._v(" mdi-alert-box-outline "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showAlertPane,
            callback: function ($$v) {
              _vm.showAlertPane = $$v
            },
            expression: "showAlertPane",
          },
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v("\n        Alerts\n        "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "", "open-delay": "350" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "ml-1",
                                      attrs: { icon: "" },
                                      on: { click: _vm.clearAlerts },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v(" mdi-notification-clear-all "),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_vm._v(" "), _c("span", [_vm._v("Clear all")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.alerts.length === 0
                ? _c("v-card-text", [_vm._v(" No alerts ")])
                : _c(
                    "v-list",
                    {
                      staticClass: "overflow-y-auto",
                      attrs: {
                        "two-line": "",
                        width: "388",
                        "max-height": "80vh",
                      },
                    },
                    [
                      _vm._l(_vm.alerts, function (alert, index) {
                        return [
                          _c(
                            "v-list-item",
                            {
                              key: "alert-" + index,
                              staticClass: "pl-2",
                              on: {
                                click: function ($event) {
                                  return _vm.openDialog(alert)
                                },
                              },
                            },
                            [
                              _c(
                                "v-badge",
                                {
                                  attrs: {
                                    left: "",
                                    inline: "",
                                    color: "transparent",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "badge",
                                        fn: function () {
                                          return [
                                            _c("astro-status-indicator", {
                                              attrs: {
                                                status:
                                                  alert.severity.toLowerCase(),
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "pt-0 pb-0" },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(alert.title) +
                                            "\n                "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(alert.body) +
                                            "\n                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.alertDialog,
            callback: function ($$v) {
              _vm.alertDialog = $$v
            },
            expression: "alertDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.selectedAlert.title) +
                      "\n        "
                  ),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("astro-status-indicator", {
                    attrs: { status: _vm.selectedAlert.severity || "normal" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", [
                _vm._v(
                  "\n        " + _vm._s(_vm.selectedAlert.body) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.alertDialog = false
                        },
                      },
                    },
                    [_vm._v("\n          Dismiss\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }