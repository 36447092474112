var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      items: _vm.scopes,
      disabled: _vm.scopes.length <= 1,
      label: "Scope",
      dense: "",
      "hide-details": "",
    },
    model: {
      value: _vm.scope,
      callback: function ($$v) {
        _vm.scope = $$v
      },
      expression: "scope",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }