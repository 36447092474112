var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", id: "openc3-nav-drawer" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-icon",
                    {
                      staticStyle: {
                        "margin-right": "auto !important",
                        "margin-left": "auto",
                        "margin-top": "0px",
                        "margin-bottom": "0px",
                      },
                    },
                    [_c("img", { attrs: { src: _vm.logo, alt: "OpenC3" } })]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "font-size": "18pt" },
                },
                [_vm._v(_vm._s(_vm.edition))]
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                { staticClass: "my-0" },
                [
                  _c(
                    "v-list-item-content",
                    _vm._l(_vm.adminTools, function (tool, name) {
                      return _c(
                        "div",
                        { key: name },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "fixcenter",
                              attrs: {
                                block: "",
                                small: "",
                                href: tool.url,
                                onclick: "singleSpaNavigate(event)",
                              },
                            },
                            [_vm._v("\n              Admin\n            ")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-treeview", {
                attrs: {
                  items: _vm.items,
                  open: _vm.initiallyOpen,
                  "item-key": "name",
                  dense: "",
                  "open-on-click": "",
                  "expand-icon": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function (ref) {
                      var item = ref.item
                      var open = ref.open
                      return [
                        !item.icon
                          ? _c("v-icon", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    open
                                      ? "mdi-chevron-down"
                                      : "mdi-chevron-right"
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _c(
                              "a",
                              {
                                attrs: {
                                  href: item.url,
                                  onclick: "singleSpaNavigate(event)",
                                },
                              },
                              [
                                _c("v-icon", [
                                  _vm._v(" " + _vm._s(item.icon) + " "),
                                ]),
                              ],
                              1
                            ),
                      ]
                    },
                  },
                  {
                    key: "label",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: item.url,
                              onclick: "singleSpaNavigate(event)",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "append",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.icon
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.newTabUrl(item),
                                  target: "_blank",
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-open-in-new")])],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            color: "tertiary darken-3",
            id: "openc3-app-toolbar",
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _vm._v(" "),
          _c("v-divider", {
            staticClass: "top-bar-divider-full-height",
            attrs: { vertical: "" },
          }),
          _vm._v(" "),
          _c("span", { staticStyle: { width: "100%" } }, [
            _c("span", { attrs: { id: "openc3-menu" } }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "justify-right mr-2 pt-2" },
            [_c("scope-selector")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "justify-right",
              attrs: { "data-test": "alert-history" },
            },
            [_c("alert-history")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "justify-right",
              attrs: { "data-test": "notifications" },
            },
            [_c("notifications")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "justify-right",
              attrs: { "data-test": "user-menu" },
            },
            [_c("user-menu")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }